export function ErrorBoundary({ error }: { error: any }) {
    console.error('ErrorBoundary showing error:', error); // Log the error for debugging purposes
  
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md">
          <p className="text-red-500 font-semibold text-lg mb-4">
            An error occurred while loading this page. Please try to reload your page.
          </p>
          <p className="text-gray-600">
            {error?.code === 'ECONNREFUSED'
              ? 'Unable to connect to the backend.'
              : error.message || ''}
          </p>
        </div>
      </div>
    );
  }
  