export default function Footer() {
    return (
        <footer className="flex flex-wrap items-center justify-between relative bg-primary px-4 md:px-10 min-h-[60px]">
            <div className="flex flex-wrap items-center justify-center py-4 flex-auto w-full">
                <p className="text-sm hover:text-white sm:text-base text-white"> <span className="font-medium"></span>  Contact Chelsea Parkinson on +0413 813 305 or <a href="mailto:md@od.com.au">md@od.com.au</a> for any assistance.</p>
                {/* <p className="text-sm hover:text-white sm:text-base text-white"> <span className="font-medium">Email us:</span><a href="mailto:md@od.com.au"> md@od.com.au</a></p> */}
            </div>
        </footer>
    )
}
